.navbar {
  background-color: $background-navbar;
  border-bottom: 0.5px solid $primary;
  font-size: 20px;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  //   height: 100px;
}

.navbar-logo {
  margin: 20px;
}

.navbar-toggle {
  align-self: center;
  justify-self: center;
  vertical-align: middle;
  margin-top: 20px;
}
