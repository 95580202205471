.carousel-tem{
    background-color: red;
    width: 100vh;
    height: 100%;

}

.carousel-image{
    max-height: 88vh;
}

.carousel-text{
    padding: 0;
    position: absolute;
    right: 80vw!important;
    // left: 10 ;
    bottom: 20vh;
    width: 30vw;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    // text-justify:newspaper;
}

.carousel-title{
    text-align: left;
    text-justify: initial;
}
.carousel-p{
    text-justify: inter-word!important;
    text-align: left;
}