//Colors

$background: #fbfcff;
$background-navbar: rgba(29, 36, 86, 0.04);
$primary: #1d2456;
$secondary: #71c1f1;

.holder-container {
  background-color: #fbfcff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
